/* DiscordVerify.css */
.discordverify-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #1c1e22 0%, #2b2e33 100%);
  }
  
  .discordverify-content {
    background-color: #2a2d32;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
    text-align: center;
    color: white;
    width: 100%;
    max-width: 450px;
  }
  
  .discordverify-header {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .discordverify-message {
    font-size: 18px;
    color: #dddddd;
    margin-bottom: 30px;
  }
  
  .discordverify-actions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
  }
  
  .discordverify-button {
    background: linear-gradient(45deg, #72a1e5, #8ed4b9);
    color: #ffffff;
    border: none;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background 0.3s ease;
    width: 100%;
  }
  
  .discordverify-button:hover {
    background: linear-gradient(45deg, #5c8cd8, #77bfa3);
  }
  