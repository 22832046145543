/* .WyvernApp {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: #222;
} */

.WyvernApp {
  position: relative;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderText{
color:aliceblue;
font-family: "HelveticaNeue-Extended";
position: absolute;
top: 15%;
text-align: center;
font-size: 14px;
}

.night {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateZ(45deg);
}

.shooting_star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #699bff);
  -webkit-animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
          animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
}
.shooting_star::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
          animation: shining 3000ms ease-in-out infinite;
}
.shooting_star::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
          animation: shining 3000ms ease-in-out infinite;
  transform: translateX(50%) rotateZ(-45deg);
}
.shooting_star:nth-child(1) {
  top: calc(50% - 24px);
  left: calc(50% - 51px);
  -webkit-animation-delay: 8161ms;
          animation-delay: 8161ms;
}
.shooting_star:nth-child(1)::before, .shooting_star:nth-child(1)::after {
  -webkit-animation-delay: 8161ms;
          animation-delay: 8161ms;
}
.shooting_star:nth-child(2) {
  top: calc(50% - 115px);
  left: calc(50% - 170px);
  -webkit-animation-delay: 6481ms;
          animation-delay: 6481ms;
}
.shooting_star:nth-child(2)::before, .shooting_star:nth-child(2)::after {
  -webkit-animation-delay: 6481ms;
          animation-delay: 6481ms;
}
.shooting_star:nth-child(3) {
  top: calc(50% - -154px);
  left: calc(50% - 199px);
  -webkit-animation-delay: 3610ms;
          animation-delay: 3610ms;
}
.shooting_star:nth-child(3)::before, .shooting_star:nth-child(3)::after {
  -webkit-animation-delay: 3610ms;
          animation-delay: 3610ms;
}
.shooting_star:nth-child(4) {
  top: calc(50% - -80px);
  left: calc(50% - 189px);
  -webkit-animation-delay: 1263ms;
          animation-delay: 1263ms;
}
.shooting_star:nth-child(4)::before, .shooting_star:nth-child(4)::after {
  -webkit-animation-delay: 1263ms;
          animation-delay: 1263ms;
}
.shooting_star:nth-child(5) {
  top: calc(50% - 171px);
  left: calc(50% - 299px);
  -webkit-animation-delay: 1245ms;
          animation-delay: 1245ms;
}
.shooting_star:nth-child(5)::before, .shooting_star:nth-child(5)::after {
  -webkit-animation-delay: 1245ms;
          animation-delay: 1245ms;
}
.shooting_star:nth-child(6) {
  top: calc(50% - -164px);
  left: calc(50% - 169px);
  -webkit-animation-delay: 7928ms;
          animation-delay: 7928ms;
}
.shooting_star:nth-child(6)::before, .shooting_star:nth-child(6)::after {
  -webkit-animation-delay: 7928ms;
          animation-delay: 7928ms;
}
.shooting_star:nth-child(7) {
  top: calc(50% - -69px);
  left: calc(50% - 192px);
  -webkit-animation-delay: 4886ms;
          animation-delay: 4886ms;
}
.shooting_star:nth-child(7)::before, .shooting_star:nth-child(7)::after {
  -webkit-animation-delay: 4886ms;
          animation-delay: 4886ms;
}
.shooting_star:nth-child(8) {
  top: calc(50% - 78px);
  left: calc(50% - 83px);
  -webkit-animation-delay: 9150ms;
          animation-delay: 9150ms;
}
.shooting_star:nth-child(8)::before, .shooting_star:nth-child(8)::after {
  -webkit-animation-delay: 9150ms;
          animation-delay: 9150ms;
}
.shooting_star:nth-child(9) {
  top: calc(50% - -26px);
  left: calc(50% - 191px);
  -webkit-animation-delay: 3378ms;
          animation-delay: 3378ms;
}
.shooting_star:nth-child(9)::before, .shooting_star:nth-child(9)::after {
  -webkit-animation-delay: 3378ms;
          animation-delay: 3378ms;
}
.shooting_star:nth-child(10) {
  top: calc(50% - -151px);
  left: calc(50% - 141px);
  -webkit-animation-delay: 8640ms;
          animation-delay: 8640ms;
}
.shooting_star:nth-child(10)::before, .shooting_star:nth-child(10)::after {
  -webkit-animation-delay: 8640ms;
          animation-delay: 8640ms;
}
.shooting_star:nth-child(11) {
  top: calc(50% - -185px);
  left: calc(50% - 157px);
  -webkit-animation-delay: 7467ms;
          animation-delay: 7467ms;
}
.shooting_star:nth-child(11)::before, .shooting_star:nth-child(11)::after {
  -webkit-animation-delay: 7467ms;
          animation-delay: 7467ms;
}
.shooting_star:nth-child(12) {
  top: calc(50% - 53px);
  left: calc(50% - 165px);
  -webkit-animation-delay: 3882ms;
          animation-delay: 3882ms;
}
.shooting_star:nth-child(12)::before, .shooting_star:nth-child(12)::after {
  -webkit-animation-delay: 3882ms;
          animation-delay: 3882ms;
}
.shooting_star:nth-child(13) {
  top: calc(50% - 132px);
  left: calc(50% - 103px);
  -webkit-animation-delay: 6988ms;
          animation-delay: 6988ms;
}
.shooting_star:nth-child(13)::before, .shooting_star:nth-child(13)::after {
  -webkit-animation-delay: 6988ms;
          animation-delay: 6988ms;
}
.shooting_star:nth-child(14) {
  top: calc(50% - 79px);
  left: calc(50% - 83px);
  -webkit-animation-delay: 5417ms;
          animation-delay: 5417ms;
}
.shooting_star:nth-child(14)::before, .shooting_star:nth-child(14)::after {
  -webkit-animation-delay: 5417ms;
          animation-delay: 5417ms;
}
.shooting_star:nth-child(15) {
  top: calc(50% - -189px);
  left: calc(50% - 25px);
  -webkit-animation-delay: 8882ms;
          animation-delay: 8882ms;
}
.shooting_star:nth-child(15)::before, .shooting_star:nth-child(15)::after {
  -webkit-animation-delay: 8882ms;
          animation-delay: 8882ms;
}
.shooting_star:nth-child(16) {
  top: calc(50% - -14px);
  left: calc(50% - 294px);
  -webkit-animation-delay: 1443ms;
          animation-delay: 1443ms;
}
.shooting_star:nth-child(16)::before, .shooting_star:nth-child(16)::after {
  -webkit-animation-delay: 1443ms;
          animation-delay: 1443ms;
}
.shooting_star:nth-child(17) {
  top: calc(50% - -92px);
  left: calc(50% - 294px);
  -webkit-animation-delay: 8375ms;
          animation-delay: 8375ms;
}
.shooting_star:nth-child(17)::before, .shooting_star:nth-child(17)::after {
  -webkit-animation-delay: 8375ms;
          animation-delay: 8375ms;
}
.shooting_star:nth-child(18) {
  top: calc(50% - -87px);
  left: calc(50% - 135px);
  -webkit-animation-delay: 9992ms;
          animation-delay: 9992ms;
}
.shooting_star:nth-child(18)::before, .shooting_star:nth-child(18)::after {
  -webkit-animation-delay: 9992ms;
          animation-delay: 9992ms;
}
.shooting_star:nth-child(19) {
  top: calc(50% - -190px);
  left: calc(50% - 42px);
  -webkit-animation-delay: 65ms;
          animation-delay: 65ms;
}
.shooting_star:nth-child(19)::before, .shooting_star:nth-child(19)::after {
  -webkit-animation-delay: 65ms;
          animation-delay: 65ms;
}
.shooting_star:nth-child(20) {
  top: calc(50% - -151px);
  left: calc(50% - 208px);
  -webkit-animation-delay: 7965ms;
          animation-delay: 7965ms;
}
.shooting_star:nth-child(20)::before, .shooting_star:nth-child(20)::after {
  -webkit-animation-delay: 7965ms;
          animation-delay: 7965ms;
}

@-webkit-keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}

@keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}
@-webkit-keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@-webkit-keyframes shooting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
  }
}
@keyframes shooting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
  }
}
@-webkit-keyframes sky {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
@keyframes sky {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}


@keyframes growAnimation {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.05); /* Adjust the scale value as needed */
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.gradient {
  position: fixed;
  --size: 250px;
  --speed: 40s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 5));
  background-image: linear-gradient(rgba(235, 55, 55, 0.85), #5b37eb);
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
}

@media (min-width: 720px) {
  .gradient {
    --size: 500px;
  }
}

/* This is just to transition when you change the viewport size. */
* {
  transition: all 0.25s ease-out;
}

.dual-button {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 15px;
}

.brand-text {
  z-index: 2;
  color: #ffffff;
  font-size: 12px;
  margin-top: 25px;
  opacity: 0.8;
}

/* Styles for the entire logo container */
.logo-container {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Align items vertically in the center */
  background-color: #1a1a1ac4;
  backdrop-filter: blur(10px);
  border-radius: 0px 0px 0px 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 2px 20px; /* Padding around the content */
  width: fit-content; /* Adjust width based on content */
  margin-left: auto;
}

/* Styles for the logo icon */
.logo-icon {
  width: 60px; /* Adjust size as needed */
  height: 60px; /* Adjust size as needed */
  margin-right: 0px;
}

.pay-text {
  color: #ff284c;
  display: flex;
  justify-content: right;
  margin-right: 5px;
}

/* Styles for the logo text */
.logo-text {
  font-size: 18px; /* Adjust text size as needed */
  color: #ffffff; /* Text color, adjust as needed */
  font-weight: bold; /* Bold text */
  font-style: italic;
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  background-color: red;
  mix-blend-mode: overlay; /* This will blend the red overlay with the video */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: soft-light; /* This will blend the red overlay with the video */
  opacity: 0.1;

  /* Dot grid styles */
  --dot-bg: rgba(255, 255, 255, 0.4); /* Semi-transparent white for background */
  --dot-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black for dots */
  --dot-size: 1px; /* Size of the dots */
  --dot-space: 4px; /* Spacing between dots */

  background:
    linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center,
    linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center,
    var(--dot-color);
  background-size: var(--dot-space) var(--dot-space);
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 360px;
}

.card {
  background-color: #26282eb7;
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 360px;
}

.card-body .title-text {
  color: #ffffff;
  font-size: 18px;
}

.title-text {
  display: flex;
  align-items: center;
}

.separator {
  margin: 0 5px; /* Adjust the margin as needed */
  color: #ffffff; /* Color of the separator */
}
.secondary-text {
  color: #7c8191; /* Set the color for the secondary text */
  font-size: 13px; /* Adjust font size as needed */
  margin-top: 10px; /* Add margin to separate it from the previous content */
}


.server-button {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-content: center;
  background: linear-gradient(to right, #e73956, #ff284c);
  color: #ffffff;
  padding: 10px 10px; /* Adjusted padding for a normal button */
  border-radius: 100px; /* Rounded corners */
  font-size: 12px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 180px; /* Set the width to 160px - 1px */
}

.server-button > div {
  margin-top: -2px;
}





.subscription-description {
  color: #7c8191;
  font-size: 14px;
  margin-top: 5px;
}

.conversion {
  display: flex;
  color: #ffffff;
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 10px; /* Add margin between options */
  justify-content: center;
}

.subscription-options {
  border: none; /* Remove border from the select box */
  position: relative; /* Position relative for absolute positioning of options */
}

.subscription-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ff284c; /* Full border with color #2e2f35 */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Add cursor pointer for clickable effect */
  padding: 10px;
  margin-bottom: 15px; /* Add margin between options */
}

.subscription-option:last-child {
  margin-bottom: 0; /* Remove margin from the last option */
}

.subscription-option:hover {
  background-color: #ff284c36; /* Darker background color on hover */
}

.subscription-option.selected {
  background: linear-gradient(to right, #e73956, #ff284c);
}

input[type='radio'] {
  display: none;
  accent-color: #ffffff;
}
.subscription-option input {
  margin-right: 10px;
  cursor: pointer; /* Add cursor pointer for clickable effect */
}

.subscription-option input:checked {
  background-color: transparent; /* Set background color to transparent */
}

.subscription-option input:checked::before {
  border-color: #000000; /* Black color for the radio button when selected */
}

.option-description {
  flex: 1;
  display: flex;
  align-items: center;
}

.option-description {
  display: flex;
  margin-left: 10px;
  align-items: center;
}

.option-description div {
  color: #ffffff; /* Same color as option price */
  margin-right: 10px; /* Add margin to separate description and price */
  font-size: 14px;
  font-style: normal;
}

.option-price {
  color: #7c8191; /* Same color as option description */
  text-align: right; /* Align the price to the right */
  flex: 1; /* Allow the price to take up the remaining space */
  margin-left: 10px; /* Add margin to separate description and price */
}

.selected-price {
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  text-align: left; /* Align to the left */
  margin: 12px 0; /* Add margin of 10px top and bottom */
  margin-top: 25px;
}

.pay-button {
  background: linear-gradient(45deg, #3f99fa, #233fe0);
  color: #fff; /* Black text color */
  width: 100%; /* Full width */
  padding: 15px; /* Add padding to the top and bottom */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-family: "HelveticaNeue-Extended";
}

.pay-button:hover {
  background: linear-gradient(45deg, #2e6fb4, #172b99);
}

input[type="text"] {
  background-color: transparent;
  border: 1px solid #4b4c55;
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 12px;
  margin: 10px 0; /* Add 10px margin to the top and bottom */
  font-family: "HelveticaNeue-Extended";
}

/* Set the placeholder text */
input[type="text"]::placeholder {
  color: #5f5f5f; /* Gray color for the placeholder */
  font-family: "HelveticaNeue-Extended";
}

.payment-method {
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  color: #ffffff;
}

.payment-method .price {
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  color: #A4FFFF;
  margin-top:5px;
}
/* Updated styles for the PayPal email section */
.payment-info,
.payment-info-title {
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  color: #ffffff;
}

.payment-info-secondary {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 25px;
  color: #8d8d8d;
}

.payment-info-secondary::before {
  content: '> ';
}

/* Make "2. Please Enter your PayPal email Here:" look like a title */
.payment-info-title::before {
  content: '2. ';
}

.payment-confirm {
  background: linear-gradient(to bottom right, #BEFAA0, #C2FFA4); /* Gradient from #BEFAA0 to #C2FFA4 */
  color: #000000; /* Black text color */
  width: 100%; /* Full width */
  padding: 3px; /* Add padding to the top and bottom */
  border: 15px solid #c2ffa4; /* Set the border color and size */
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold; /* Bold text */
  margin-top: 15px;
  margin-right: 15px;
  font-family: "HelveticaNeue-Extended";
}

.payment-confirm:hover {
  background-color: #a4ff8c; /* Darker green on hover */
}

.payment-error {
  display: flex;
  color: rgb(255, 115, 150);
  margin-top: 10px;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}

.payment-success {
  display: flex;
  color: rgb(95, 255, 143);
  margin-top: 10px;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}

.donation-link-button {
  background-color: #a4ffff; /* Light blue color for the donation link button */
  color: #000000; /* Black text color */
  width: 100%; /* Full width */
  padding: 5px; /* Add padding to the top and bottom */
  border: 5px solid #a4ffff; /* Set the border color and size */
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold; /* Bold text */
  margin-top: 15px;
  margin-bottom: 25px;
  margin-right: 15px;
  font-family: "HelveticaNeue-Extended";
}

.donation-link-button:hover {
  background-color: #92e6ff; /* Darker blue on hover */
  border-color: #92e6ff; /* Darker border color on hover */
}

.success-message {
  margin-top: 20px;
}

.green-text {
  color: #4caf50;
}

.white-text {
  color: #ffffff;
}

.gray-text {
  color: #7c8191;
}

.smaller-text {
  font-size: 14px;
}

.big-text {
  font-size: 20px;
  font-weight: bold;
}