.HomeApp {
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    height: 100vh;
    overflow: hidden;
    display: flex;
    font-family: "Anton", sans-serif;
    justify-content: center;
    align-items: center;
  }
  
.HeaderText{
  color:aliceblue;
  font-family: "HelveticaNeue-Extended";
  position: absolute;
  top: 15%;
  text-align: center;
  font-size: 14px;
}

  .night {
    position: fixed;
    width: 100%;
    height: 100%;
    transform: rotateZ(45deg);
  }
  
  .shooting_star {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 2px;
    background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
    border-radius: 999px;
    filter: drop-shadow(0 0 6px #699bff);
    -webkit-animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
            animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
  }
  .shooting_star::before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    -webkit-animation: shining 3000ms ease-in-out infinite;
            animation: shining 3000ms ease-in-out infinite;
  }
  .shooting_star::after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    -webkit-animation: shining 3000ms ease-in-out infinite;
            animation: shining 3000ms ease-in-out infinite;
    transform: translateX(50%) rotateZ(-45deg);
  }
  .shooting_star:nth-child(1) {
    top: calc(50% - 24px);
    left: calc(50% - 51px);
    -webkit-animation-delay: 8161ms;
            animation-delay: 8161ms;
  }
  .shooting_star:nth-child(1)::before, .shooting_star:nth-child(1)::after {
    -webkit-animation-delay: 8161ms;
            animation-delay: 8161ms;
  }
  .shooting_star:nth-child(2) {
    top: calc(50% - 115px);
    left: calc(50% - 170px);
    -webkit-animation-delay: 6481ms;
            animation-delay: 6481ms;
  }
  .shooting_star:nth-child(2)::before, .shooting_star:nth-child(2)::after {
    -webkit-animation-delay: 6481ms;
            animation-delay: 6481ms;
  }
  .shooting_star:nth-child(3) {
    top: calc(50% - -154px);
    left: calc(50% - 199px);
    -webkit-animation-delay: 3610ms;
            animation-delay: 3610ms;
  }
  .shooting_star:nth-child(3)::before, .shooting_star:nth-child(3)::after {
    -webkit-animation-delay: 3610ms;
            animation-delay: 3610ms;
  }
  .shooting_star:nth-child(4) {
    top: calc(50% - -80px);
    left: calc(50% - 189px);
    -webkit-animation-delay: 1263ms;
            animation-delay: 1263ms;
  }
  .shooting_star:nth-child(4)::before, .shooting_star:nth-child(4)::after {
    -webkit-animation-delay: 1263ms;
            animation-delay: 1263ms;
  }
  .shooting_star:nth-child(5) {
    top: calc(50% - 171px);
    left: calc(50% - 299px);
    -webkit-animation-delay: 1245ms;
            animation-delay: 1245ms;
  }
  .shooting_star:nth-child(5)::before, .shooting_star:nth-child(5)::after {
    -webkit-animation-delay: 1245ms;
            animation-delay: 1245ms;
  }
  .shooting_star:nth-child(6) {
    top: calc(50% - -164px);
    left: calc(50% - 169px);
    -webkit-animation-delay: 7928ms;
            animation-delay: 7928ms;
  }
  .shooting_star:nth-child(6)::before, .shooting_star:nth-child(6)::after {
    -webkit-animation-delay: 7928ms;
            animation-delay: 7928ms;
  }
  .shooting_star:nth-child(7) {
    top: calc(50% - -69px);
    left: calc(50% - 192px);
    -webkit-animation-delay: 4886ms;
            animation-delay: 4886ms;
  }
  .shooting_star:nth-child(7)::before, .shooting_star:nth-child(7)::after {
    -webkit-animation-delay: 4886ms;
            animation-delay: 4886ms;
  }
  .shooting_star:nth-child(8) {
    top: calc(50% - 78px);
    left: calc(50% - 83px);
    -webkit-animation-delay: 9150ms;
            animation-delay: 9150ms;
  }
  .shooting_star:nth-child(8)::before, .shooting_star:nth-child(8)::after {
    -webkit-animation-delay: 9150ms;
            animation-delay: 9150ms;
  }
  .shooting_star:nth-child(9) {
    top: calc(50% - -26px);
    left: calc(50% - 191px);
    -webkit-animation-delay: 3378ms;
            animation-delay: 3378ms;
  }
  .shooting_star:nth-child(9)::before, .shooting_star:nth-child(9)::after {
    -webkit-animation-delay: 3378ms;
            animation-delay: 3378ms;
  }
  .shooting_star:nth-child(10) {
    top: calc(50% - -151px);
    left: calc(50% - 141px);
    -webkit-animation-delay: 8640ms;
            animation-delay: 8640ms;
  }
  .shooting_star:nth-child(10)::before, .shooting_star:nth-child(10)::after {
    -webkit-animation-delay: 8640ms;
            animation-delay: 8640ms;
  }
  .shooting_star:nth-child(11) {
    top: calc(50% - -185px);
    left: calc(50% - 157px);
    -webkit-animation-delay: 7467ms;
            animation-delay: 7467ms;
  }
  .shooting_star:nth-child(11)::before, .shooting_star:nth-child(11)::after {
    -webkit-animation-delay: 7467ms;
            animation-delay: 7467ms;
  }
  .shooting_star:nth-child(12) {
    top: calc(50% - 53px);
    left: calc(50% - 165px);
    -webkit-animation-delay: 3882ms;
            animation-delay: 3882ms;
  }
  .shooting_star:nth-child(12)::before, .shooting_star:nth-child(12)::after {
    -webkit-animation-delay: 3882ms;
            animation-delay: 3882ms;
  }
  .shooting_star:nth-child(13) {
    top: calc(50% - 132px);
    left: calc(50% - 103px);
    -webkit-animation-delay: 6988ms;
            animation-delay: 6988ms;
  }
  .shooting_star:nth-child(13)::before, .shooting_star:nth-child(13)::after {
    -webkit-animation-delay: 6988ms;
            animation-delay: 6988ms;
  }
  .shooting_star:nth-child(14) {
    top: calc(50% - 79px);
    left: calc(50% - 83px);
    -webkit-animation-delay: 5417ms;
            animation-delay: 5417ms;
  }
  .shooting_star:nth-child(14)::before, .shooting_star:nth-child(14)::after {
    -webkit-animation-delay: 5417ms;
            animation-delay: 5417ms;
  }
  .shooting_star:nth-child(15) {
    top: calc(50% - -189px);
    left: calc(50% - 25px);
    -webkit-animation-delay: 8882ms;
            animation-delay: 8882ms;
  }
  .shooting_star:nth-child(15)::before, .shooting_star:nth-child(15)::after {
    -webkit-animation-delay: 8882ms;
            animation-delay: 8882ms;
  }
  .shooting_star:nth-child(16) {
    top: calc(50% - -14px);
    left: calc(50% - 294px);
    -webkit-animation-delay: 1443ms;
            animation-delay: 1443ms;
  }
  .shooting_star:nth-child(16)::before, .shooting_star:nth-child(16)::after {
    -webkit-animation-delay: 1443ms;
            animation-delay: 1443ms;
  }
  .shooting_star:nth-child(17) {
    top: calc(50% - -92px);
    left: calc(50% - 294px);
    -webkit-animation-delay: 8375ms;
            animation-delay: 8375ms;
  }
  .shooting_star:nth-child(17)::before, .shooting_star:nth-child(17)::after {
    -webkit-animation-delay: 8375ms;
            animation-delay: 8375ms;
  }
  .shooting_star:nth-child(18) {
    top: calc(50% - -87px);
    left: calc(50% - 135px);
    -webkit-animation-delay: 9992ms;
            animation-delay: 9992ms;
  }
  .shooting_star:nth-child(18)::before, .shooting_star:nth-child(18)::after {
    -webkit-animation-delay: 9992ms;
            animation-delay: 9992ms;
  }
  .shooting_star:nth-child(19) {
    top: calc(50% - -190px);
    left: calc(50% - 42px);
    -webkit-animation-delay: 65ms;
            animation-delay: 65ms;
  }
  .shooting_star:nth-child(19)::before, .shooting_star:nth-child(19)::after {
    -webkit-animation-delay: 65ms;
            animation-delay: 65ms;
  }
  .shooting_star:nth-child(20) {
    top: calc(50% - -151px);
    left: calc(50% - 208px);
    -webkit-animation-delay: 7965ms;
            animation-delay: 7965ms;
  }
  .shooting_star:nth-child(20)::before, .shooting_star:nth-child(20)::after {
    -webkit-animation-delay: 7965ms;
            animation-delay: 7965ms;
  }
  
  @-webkit-keyframes tail {
    0% {
      width: 0;
    }
    30% {
      width: 100px;
    }
    100% {
      width: 0;
    }
  }
  
  @keyframes tail {
    0% {
      width: 0;
    }
    30% {
      width: 100px;
    }
    100% {
      width: 0;
    }
  }
  @-webkit-keyframes shining {
    0% {
      width: 0;
    }
    50% {
      width: 30px;
    }
    100% {
      width: 0;
    }
  }
  @keyframes shining {
    0% {
      width: 0;
    }
    50% {
      width: 30px;
    }
    100% {
      width: 0;
    }
  }
  @-webkit-keyframes shooting {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(300px);
    }
  }
  @keyframes shooting {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(300px);
    }
  }
  @-webkit-keyframes sky {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
  @keyframes sky {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }