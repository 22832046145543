@font-face {
  font-family: "HelveticaNeue-Extended";
  src: url("https://db.onlinewebfonts.com/t/32d0ba9a82b15c3100ae9dd9e614ef11.eot");
  src: url("https://db.onlinewebfonts.com/t/32d0ba9a82b15c3100ae9dd9e614ef11.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/32d0ba9a82b15c3100ae9dd9e614ef11.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/32d0ba9a82b15c3100ae9dd9e614ef11.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/32d0ba9a82b15c3100ae9dd9e614ef11.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/32d0ba9a82b15c3100ae9dd9e614ef11.svg#HelveticaNeue-Extended")format("svg");
}

body {
  margin: 0;
  font-family: "HelveticaNeue-Extended";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
