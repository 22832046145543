/* Success.css */
.success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #212328 50%, #1d1e23 50%);
}

.success-content {
  background-color: #26282e;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  text-align: center;
  color: white;
}

.success-header {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.success-checkmark {
  color: #4caf50; /* Success green color */
}

.success-message {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-top: -20px;
}

.success-key {
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  color: #ffffff;
}

.key-token {
  display: inline-block;
  border: 1px solid #97e954; /* Green border */
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  background-color: #31363f; /* Darker background color for key token */
  position: relative;
  border-radius: 10px;
  font-size: 14px;
}

.clipboard-icon {
  margin-left: 10px;
  color: #97e954; /* Green color */
}

.copy-confirmation {
  color: #4caf50; /* Success green color */
  font-weight: bold;
  margin-top: 5px;
}

.continue-button {
  background: linear-gradient(to right, #7fa5ce, #91b6a9);
  color: #ffffff;
  border: none;
  padding: 15px 20px; /* Adjusted padding for a normal button */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  font-family: "HelveticaNeue-Extended";
  width: auto; /* Adjust width as needed */
}

.continue-button:hover {
  background: linear-gradient(to right, #6e93c4, #7fa5ce);
}

/* Additional styling if needed */
